<template>
	<!-- <div id="card-inteface" @click="fetchDataProducts"> -->
	<div id="card-inteface">
		<div id="card-inteface__attributes">
			<card-attributes
				:activeAttribute="this.attributeId"
				:cardId="this.cardId"
			></card-attributes>
			<attributes-info
				:cardId="this.cardId"
				:attributeObj="this.argObj"
			></attributes-info>
		</div>
	</div>
</template>

<script>
import CardAttributes from "@/components/cardsInterface/CardAttributes.vue";
import AttributesInfo from "@/components/cardsInterface/AttributesInfo.vue";
export default {
	name: "CardInterface",
	components: { CardAttributes, AttributesInfo },
	props: {
		cardId: Number,
		attributeId: String,
	},
	data() {
		return {
			objCard: {
				id: 1,
				information: {
					nameProduct: {
						title: "Имя продукта",
						value: "Название продукта",
						typeElement: "Text",
					},
					nameProduct2: {
						title: "Имя продукта",
						value: "Название продукта",
						typeElement: "Text",
					},
				},
				functionalDescription: {
					nameProduct: {
						title: "Имя dwq",
						value: "Название rwe",
						typeElement: "Text",
					},
				},
			},
			dictAttribute: {
				info: "information",
				func: "functionalDescription",
				tech: "technicalDescription",
				team: "team",
				cost: "developmentСost",
				license: "licensesPayments",
				docs: "documentation",
				certificates: "reportsCertificates",
				patent: "patenting",
			},
			activeAttribute: "", // example patenting
			currentAttribute: "", // example patent
			currentCard: "", // exaple 1
			argObj: {},
		};
	},
	watch: {
		"$route.path": {
			handler() {
				this.fetchDataProducts();
			},
		},
	},
	created() {
		this.fetchDataProducts();
	},
	methods: {
		async fetchDataProducts() {
			try {
				let data = {};

				const urlResponse =
					"https://api.tryhard.fun/Card/GetCard/" + this.$route.params.id; //+'/'+this.$route.params.attribute;
				const response = await fetch(urlResponse);
				data = await response.json();
				console.log(data);

				this.currentAttribute = this.$route.params.attribute;
				this.currentCard = this.$route.params.id;
				this.activeAttribute = this.dictAttribute[this.currentAttribute];
				this.objCard = await JSON.parse(JSON.stringify(data));
				if (!data) {
					console.log(
						"SERVER: {} answer from server :CardInterface in fetchDataProducts"
					);
				}
			} catch (e) {
				console.log("Error: in CardInterface in fetchDataProductss");
			}
			try {
				this.argObj = JSON.parse(
					JSON.stringify(this.objCard[this.activeAttribute])
				);
				console.log(
					"Отправленный объект из CardInterface" + JSON.stringify(this.argObj)
				);
			} catch (e) {
				console.log("Error_not_critical: in CardInterface in end fetching");
			}
		},
	},
};
</script>

<style scoped>
#card-inteface {
	display: flex;
}

#card-inteface__attributes {
	display: flex;
	width: 100%;
}
</style>
