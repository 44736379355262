<template>
	<div v-if="localCards.length > 0" id="list-card-changes" class="cardLists">
		<h1 id="title-last-changes">
			{{ title }}
		</h1>
		<div id="listCards">
			<card-change
				@click="fetchLastChanges"
				v-for="(card, index) in localCards"
				:key="index"
				:objChanges="card"
			/>
		</div>
	</div>
	<div v-else id="emptyListChanges">
		<h1>Нет последних изменений</h1>
	</div>
</template>

<script>
import CardChange from "./cards/CardChange.vue";
export default {
	name: "ListCardChanges",
	components: { CardChange },
	props: {
		title: String,
	},
	data() {
		return {
			localCards: [],
		};
	},
	created() {
		this.fetchLastChanges();
	},
	methods: {
		async fetchLastChanges() {
			try {
				const response = await fetch(
					"https://api.tryhard.fun/LastChanges/GetLastChanges"
				);
				const data = await response.json();
				console.log("logging: from SERVER last changes", data);
				if (!data || data.length === 0) {
					console.log("SERVER: Cards of Changes is empty -> set default card");
					return;
				}
				this.localCards = JSON.parse(JSON.stringify(data));
				this.localCards.forEach((item) => {
					item.path = "/systems/card/" + item.idCard + "/info";
				});
			} catch (e) {
				console.log("logging: Error fetching lastChanges");
			}
		},
		async test_fetchdata() {
			setInterval(() => this.fetchLastChanges(), 3000);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
	background-color: white;
	font-size: 20px;
	padding-right: 8px;
}
#listCards {
	display: flex;
	/* justify-content: space-evenly; */
	align-items: center;
	/* flex-wrap: wrap; */
	gap: 10px;
}
.cardLists {
	display: flex;
	flex-direction: column;
}
</style>

<!-- localCards: [ // заглушка для листа изменений
                {
                    id: 1,
                    idCard: 0,
                    changeField: "Изменено поле “Название”",
                    nameProduct: "E044. Управление ролями и дост...",
                    oldValue: "Управление ролями",
                    newValue: "Управление ролями и доступом",
                    dateChange: "25.08.2023",
                    path: "/systems/card/1/info"
                },
                {
                    id: 2,
                    idCard: 1,
                    changeField: "Изменено поле “Название”",
                    nameProduct: "E044. Управление ролями и дост...",
                    oldValue: "Управление ролями",
                    newValue: "Управление ролями и доступом",
                    dateChange: "25.08.2001",
                    path: "/systems/card/2/info"
                }
            ] -->
