<template>
<!-- {{ this.objAttribute }} -->
    <div v-if="objAttribute && objAttribute.typeElement && objAttribute.typeElement == 'Input'">
        <attribute-text :obj-text="objAttribute"></attribute-text>
    </div>
    <div v-else-if="this.objAttribute.typeElement == 'Group'">
        <group-attribute :obj-group="objAttribute"></group-attribute>
    </div>
    <div v-else-if="this.objAttribute[0] && this.objAttribute[0].typeElement == 'Worker'">
        <!-- {{ this.objAttribute }} -->
        <worker-attribute v-for="worker in objAttribute" :obj-worker="worker" :key="worker.id"></worker-attribute>
    </div>
    <div v-else-if="this.objAttribute.typeElement == 'Select'">
        <attribute-list :obj-select="objAttribute"></attribute-list>
    </div>
    <div v-else-if="this.objAttribute.typeElement == 'TextArea'">
        <attribute-text-area :obj-text-area="objAttribute"></attribute-text-area>
    </div>
    <div v-else-if="this.objAttribute.typeElement == 'FileElement'"><!-- заглушка на AttributeText-->
        <attribute-text :obj-text="objAttribute"></attribute-text>
    </div>
    
    <div v-else-if="this.objAttribute && this.objAttribute != {} && this.objAttribute.length > 0">
        <attribute-text :obj-text="objAttribute"></attribute-text>
        <!-- Неизвестный тип на данный момент {{ this.objAttribute }} -->
    </div>
</template>

<script> 
import AttributeText from "@/components/cardsInterface/cardAttributes/productAttributes/AttributeText.vue";
import AttributeList from "@/components/cardsInterface/cardAttributes/productAttributes/AttributeList.vue";
import AttributeTextArea from "@/components/cardsInterface/cardAttributes/productAttributes/AttributeTextArea.vue";

    export default {
        name: "DrawAttribute",
        props:{
            objAttribute: Object,
        },
        components:{
            AttributeTextArea, AttributeText, AttributeList, 
        },
    }
</script>

<style lang="scss" scoped>

</style>