<template>
	<div :id="workerAttributeId" class="worker-display">
		<div class="worker-attribute__header">
			<label
				id="header__title"
				for="exampleFormControlInput1"
				class="form-label"
				style="padding: 0px"
				>Сотрудник</label
			>
			<div class="header__button">
				<button
					type="button"
					class="btn-close interface_delete_employee"
					aria-label="Close"
					style="display: none"
					data-bs-toggle="modal"
					:data-bs-target="'#modalDelete-' + objWorker.id"
				></button>
				<div
					class="modal fade"
					:id="'modalDelete-' + objWorker.id"
					:aria-labelledby="'exampleModalLabel' + objWorker.id"
					:tabindex="-objWorker.id"
					aria-hidden="true"
				>
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h1
									class="modal-title fs-5"
									:id="'exampleModalLabel' + objWorker.id"
								>
									Удаление сотрудника
								</h1>
								<button
									type="button"
									class="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>
							<div class="modal-body">Удалить сотрудника?</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-primary"
									data-bs-dismiss="modal"
									@click="
										() => {
											setDeleteStatus(true, objWorker.id);
											deleteEmployee(workerAttributeId);
										}
									"
								>
									Удалить
								</button>
								<button
									type="button"
									class="btn btn-secondary"
									data-bs-dismiss="modal"
									@click="setDeleteStatus(false)"
								>
									Отменить
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="list-employees">
			<draw-attribute
				:obj-attribute="objWorker.role"
				:key="objWorker.role.id"
			></draw-attribute>
			<draw-attribute
				:obj-attribute="objWorker.fullName"
				:key="objWorker.fullName.id"
			></draw-attribute>
			<draw-attribute
				:obj-attribute="objWorker.joiningDate"
				:key="objWorker.joiningDate.id"
			></draw-attribute>
			<draw-attribute
				:obj-attribute="objWorker.dateOfDismissal"
				:key="objWorker.dateOfDismissal.id"
			></draw-attribute>
			<draw-attribute
				:obj-attribute="objWorker.participation"
				:key="objWorker.participation.id"
			></draw-attribute>
			<draw-attribute
				:obj-attribute="objWorker.consent"
				:key="objWorker.consent.id"
			></draw-attribute>
			<draw-attribute
				:obj-attribute="objWorker.comments"
				:key="objWorker.comments.id"
			></draw-attribute>
		</div>
	</div>
	<!-- {{ objWorker.id }} -->
</template>

<script>
// import OkCancelModal from "@/components/modals/OkCancelModal.vue";
export default {
	name: "WorkerAttribute",
	comments: {
		// OkCancelModal
	},
	props: {
		objWorker: {
			id: Number,
			typeElement: String,
			role: Object,
			fullName: Object,
			joiningDate: Object,
			dateOfDismissal: Object,
			participation: Object,
			consent: Object,
			comments: Object,
		},
	},
	data() {
		return {
			workerAttributeId: "worker-attribute" + this.objWorker.id,
			deleteConfirmationModal: {
				title: "Подтверждение удаления",
				textModal: "Вы уверены, что хотите удалить этого сотрудника?",
				okName: "Удалить",
				cancelName: "Отмена",
				butTarget: "#exampleModal-1",
			},
			isDelete: false,
		};
	},
	components: {},
	methods: {
		setDeleteStatus(status) {
			this.isDelete = status;
		},
		async deleteEmployee() {
			// Открываем модальное окно и ожидаем результата
			if (!this.isDelete) {
				return;
			}
			// alert(`https://api.tryhard.fun/Card/DeleteWorker/${this.$route.params.id}?idWorker=${this.objWorker.id}`);
			const requestOptions = {
				method: "DELETE",
			};
			fetch(
				`https://api.tryhard.fun/Card/DeleteWorker/${this.$route.params.id}?idWorker=${this.objWorker.id}`,
				requestOptions
			)
				.then((response) => response)
				.then((data) => console.log(data))
				.catch((error) => {
					console.log(`Error: comp: Worker in deleteEmployee: ${error}`);
					console.log(
						`url: https://api.tryhard.fun/Card/DeleteWorker/${this.$route.params.id}?idWorker=${this.objWorker.id}`
					);
				});
			console.log(`logging: to delete this obj id: ${this.workerAttributeId}`);
			document.getElementById(this.workerAttributeId).remove();
			// this.$router.go(0);
		},
	},
};
</script>

<style scoped>
.list-employees {
	padding-left: 20px;
}

.worker-attribute__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header__button {
	justify-content: flex-end;
}

#header__title {
	display: flex;
	align-items: center;
	padding: 0px;
	margin: 0px;
}
</style>
