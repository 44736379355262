<template>
	<div v-if="this.$route.path != '/404' && this.$route.path != '/inDeveloping'" class="app">
		<systems-settings></systems-settings>
		<div class="wrap">
			<router-view></router-view>
		</div>
	</div>
	<div v-else-if="this.$route.path == '/inDeveloping'" id="inDeveloping">
		<h1>В разработке</h1>
	</div>
	<div v-else id="page-404">
		<h1>Page Not Found</h1>
	</div>
	<!-- <div class="fwefgw"><PageCardOptions></PageCardOptions></div> -->
</template>

<script>
import SystemsSettings from "@/SystemsSettings.vue";
// import PageCardOptions from "@/pages/PageCardOptions.vue";
	export default {
		components: { SystemsSettings, },
	}
</script>

<style>
@font-face {
    font-family: 'Inter';
    src: url('../fonts/InterRegular/InterRegular.ttf') format('truetype');
}
html, body{
	font-family: Inter;
}
.app{
	display: flex;
}
.cardLists{
	display: flex;
}
input:read-only {
	padding: 0px;
	border: 0px;
	padding-left: 20px;
	background-color: transparent;
}

</style>

















<!-- 
<template>
	<list-card-changes title="Последние изменения"/>
	<list-card-products title="Продукты"/>
</template>

<script>
import ListCardChanges from "./components/ListCardChanges.vue";
import ListCardProducts from "./components/ListCardProducts.vue";
export default {
	name: "App",
	components: {
		ListCardChanges, ListCardProducts
	},
};
</script>

<style></style> -->
