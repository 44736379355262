<template>
	<router-link class="text-decoration-none" :to="{ path: objCard.path }">
		<div class="card text-bg-light" style="max-width: 20rem">
			<!-- <div class="card-title"> -->
			<h3 class="card-header">
				<div class="card-header__text">
					{{ objCard.codeProduct }}. {{ objCard.nameProduct }}
				</div>
				<div
					class="card-status circle"
					:style="{ 'background-color': nameStatus[objCard.status].color }"
				></div>
			</h3>

			<!-- </div> -->
			<div class="card-body">
				<div class="card-product-owner">
					<strong>PO:</strong> {{ objCard.productOwner }}
				</div>
				<!-- <div class="card-live-cycle">
					<strong>ЖЦ:</strong> {{ nameStatus[objCard.status].name }}
				</div> -->
				<div class="card-last-version">
					<strong class="light_strong">Последняя версия:</strong>
					{{ objCard.latestVersion }}
				</div>
				<div class="card-date-release">
					<strong class="light_strong">Дата релиза:</strong>
					{{ objCard.releaseDate.split(" ")[0] }}
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
export default {
	name: "CardProduct",
	props: {
		objCard: {
			id: Number,
			codeProduct: String,
			nameProduct: String,
			productOwner: String,
			status: String,
			latestVersion: String,
			releaseDate: String,
			path: String,
		},
	},
	data() {
		return {
			cardInformation: Array,
			nameStatus: {
				0: { name: "Черновик", color: "#ddfb29" },
				1: { name: "Инициатива", color: "#75ff21" },
				2: { name: "Разработка", color: "#00ffd1" },
				3: { name: "ОЭ - опытная эксплуатация", color: "#5c94ff" },
				4: { name: "ПЭ - производственная эксплуатация", color: "#9a5cff" },
				5: { name: "Развитие", color: "#ff3dd4" },
				6: { name: "Вывод", color: "#ff5c5c" },
				7: { name: "Отменен", color: "#ff0000" },
			},
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
	background-color: white;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 8px;
}

.card-header__text {
	align-items: flex-start;
}

.item__cycle {
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

	min-height: 25px;
	min-width: 25px;

	shape-outside: circle();
	clip-path: circle();
	align-items: center;
}
.card-status {
	/* display: inline-block; */
	min-width: 25px;
	min-height: 25px;
	border-radius: 50%;
	text-align: center;
	line-height: 25px;
	margin: 0px 10px;
	/* margin-right: 10px; */
}
</style>
