<template>
	<details>
		<summary class="group_title">{{ objGroup.title }}</summary>
		<div class="group-elements" style="padding-left: 40px">
			<div v-if="objGroup && objGroup.groups && objGroup.groups.length > 0">
				<draw-attribute
					v-for="attribute in objGroup.groups"
					:key="attribute.id"
					:obj-attribute="attribute"
				>
				</draw-attribute>
			</div>

			<!-- <div style="display: none">{{ objGroup }}</div>

			<div v-if="objGroup && objGroup.groups && objGroup.groups.length > 0">
				<draw-attribute
					v-for="attribute in objGroup.groups"
					:key="attribute.id"
					:obj-attribute="attribute"
				>
				</draw-attribute>
			</div> -->

			<div
				v-if="
					objGroup &&
					objGroup.inputs &&
					objGroup.inputs.length &&
					!!objGroup.inputs == true &&
					objGroup.inputs.length > 0
				"
			>
				<draw-attribute
					v-for="attribute in objGroup.inputs"
					:key="attribute.id"
					:obj-attribute="attribute"
				>
				</draw-attribute>
			</div>

			<div
				v-if="
					objGroup &&
					objGroup.textAreas &&
					objGroup.textAreas.length &&
					!!objGroup.textAreas == true &&
					objGroup.textAreas.length > 0
				"
			>
				<draw-attribute
					v-for="attribute in objGroup.textAreas"
					:key="attribute.id"
					:obj-attribute="attribute"
				>
				</draw-attribute>
			</div>

			<div
				v-if="
					objGroup && objGroup.fileElements && objGroup.fileElements.length > 0
				"
			>
				<draw-attribute
					v-for="attribute in objGroup.fileElements"
					:key="attribute.id"
					:obj-attribute="attribute"
				>
				</draw-attribute>
			</div>

			<div v-if="objGroup && objGroup.selects && objGroup.selects.length > 0">
				<draw-attribute
					v-for="attribute in objGroup.selects"
					:key="attribute.id"
					:obj-attribute="attribute"
				>
				</draw-attribute>
			</div>
			<!-- <div v-if="objGroup.selects.length > 0">
                <draw-attribute v-for="(attribute, index) in objGroup.selects" :key="index" 
                                :obj-attribute="attribute">

                </draw-attribute>
            </div>
            <div v-if="objGroup.dateInputs.length > 0">
                <draw-attribute v-for="(attribute, index) in objGroup.dateInputs" :key="index" 
                                :obj-attribute="attribute">

                </draw-attribute>
            </div> -->
			<!-- <div v-else-if="objGroup.intInputs.length > 0">
                <draw-attribute v-for="(attribute, index) in objGroup.intInputs" :key="index" 
                                :obj-attribute="attribute">

                </draw-attribute>
            </div> -->

			<!-- <div v-if="objGroup.workers.length > 0">
                <draw-attribute v-for="(attribute, index) in objGroup.workers" :key="index" 
                                :obj-attribute="attribute">

                </draw-attribute>
            </div> -->
		</div>
	</details>
</template>

<script>
export default {
	name: "GroupAttribute",
	props: {
		objGroup: {
			title: String,
			addElements: Boolean,
			typeElement: String,
			groups: Array,
			inputs: Array,
			textAreas: Array,
			selects: Array,
			dateInputs: Array,
			intInputs: Array,
			fileElements: Array,
			workers: Array,
		},
	},
	components: {},
	data() {
		return {
			cardInformation: Array,
		};
	},
	methods: {},
};
</script>

<style lang="scss" scoped></style>
