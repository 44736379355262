<template>
	<div class="textArea-element">
		<label for="exampleFormControlTextarea1" class="form-label">{{
			objTextArea.title
		}}</label>
		<!-- <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"  /> -->
		<textarea
			:data-input-id="obj.id"
			class="interface_input form-control"
			name=""
			id=""
			cols="30"
			rows="3"
			v-model="obj.value"
			:readonly="true"
		/>
	</div>
</template>

<script>
export default {
	name: "AttributeTextArea",
	props: {
		objTextArea: {
			title: String,
			value: String,
		},
	},
	data() {
		return {
			obj: Object,
		};
	},
	created() {
		this.obj = this.objTextArea;
	},
};
</script>

<style lang="scss" scoped></style>
