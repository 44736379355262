<template>
	<div class="input-group">
		<input
			id="searchingText"
			type="text"
			class="form-control"
			placeholder="Введите название системы"
			aria-label="Поиск"
			aria-describedby="button-addon2"
			v-model="searchText"
			@keyup.enter="enterPress"
		/>
		<button
			class="btn btn-outline-secondary"
			type="button"
			id="button-addon2"
			@click="searchClicked"
		>
			Найти
		</button>
	</div>
</template>

<script>
export default {
	name: "SearchProduct",
	data() {
		return {
			searchText: "",
		};
	},
	methods: {
		async findProduct() {
			//https://api.tryhard.fun/Card/FindSystems?request=%D0%BF%D1%80%D0%B8%D0%B2%D0%B5%D1%82
			let text = document.getElementById("searchingText").value;
			const requestOptions = {
				method: "GET",
			};
			fetch(
				`https://api.tryhard.fun/Card/FindSystems?request=${text}`,
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => console.log(data))
				.catch((error) => {
					console.log(`Error: comp: SearchProduct in findProduct: ${error}`);
				});
			this.$router.go(0);
		},
		searchClicked() {
			this.$emit("searchClicked", this.searchText);
		},
		enterPress() {
			this.$emit("searchClicked", this.searchText);
		},
	},
};
</script>

<style lang="scss" scoped></style>
