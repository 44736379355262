<template>
	<div id="system-settings">
		<div id="logo-iptimizer">
			<svg
				width="30"
				height="30"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M18 2.01539C17.1904 2.00201 16.2113 2 15 2C11.138 2 9.63645 2.02042 8.46441 2.36456C5.52566 3.22746 3.22746 5.52566 2.36456 8.46441C2.02042 9.63645 2 11.138 2 15C2 18.862 2.02042 20.3636 2.36456 21.5356C3.22746 24.4743 5.52566 26.7725 8.46441 27.6354C9.63645 27.9796 11.138 28 15 28C16.2113 28 17.1904 27.998 18 27.9846V21.5699C18 19.5771 16.0071 18.1996 14.1429 18.9039C11.6572 19.8429 9 18.0062 9 15.3491V14.1469C9 11.473 11.7522 9.68443 14.1956 10.7704C15.9849 11.5656 18 10.2561 18 8.29836V2.01539ZM20 27.8995V21.5699C20 18.1787 16.6085 15.8345 13.4361 17.0329C12.2587 17.4777 11 16.6077 11 15.3491V14.1469C11 12.9203 12.2625 12.0999 13.3833 12.598C16.4949 13.981 20 11.7037 20 8.29836V2.10046C20.625 2.15453 21.1028 2.23747 21.5356 2.36456C24.4743 3.22746 26.7725 5.52566 27.6354 8.46441C27.9796 9.63645 28 11.138 28 15C28 18.862 27.9796 20.3636 27.6354 21.5356C26.7725 24.4743 24.4743 26.7725 21.5356 27.6354C21.1028 27.7625 20.625 27.8455 20 27.8995ZM15 30C11.279 30 9.41844 30 7.90094 29.5544C4.30913 28.4998 1.50023 25.6909 0.445577 22.0991C0 20.5816 0 18.721 0 15C0 11.279 0 9.41844 0.445577 7.90094C1.50023 4.30913 4.30913 1.50023 7.90094 0.445577C9.41844 0 11.279 0 15 0C18.721 0 20.5816 0 22.0991 0.445577C25.6909 1.50023 28.4998 4.30913 29.5544 7.90094C30 9.41844 30 11.279 30 15C30 18.721 30 20.5816 29.5544 22.0991C28.4998 25.6909 25.6909 28.4998 22.0991 29.5544C20.5816 30 18.721 30 15 30Z"
					fill="#485AFD"
				/>
			</svg>
			iptimizer
		</div>
		<div id="system-menu">
			<menu-system
				:arrayLogoAndTitles="[
					{
						logo: 'https://icons.getbootstrap.com/assets/icons/journal-code.svg',
						title: 'Системы',
						path: '/systems',
					},
					// {
					// 	logo: 'https://icons.getbootstrap.com/assets/icons/gear.svg',
					// 	title: 'Настройки',
					// 	path: '/settings',
					// },
				]"
			/>
		</div>
		<div id="legend-status">
			<h3 id="lstatus__title">Легенда ЖЦ</h3>
			<div id="lstatus__body">
				<div class="lstatus__body__item">
					<div class="item__cycle circle" style="background: #ddfb29"></div>
					<div class="item_text">Черновик</div>
				</div>
				<div class="lstatus__body__item">
					<div class="item__cycle circle" style="background: #75ff21"></div>
					<div class="item_text">Инициатива</div>
				</div>
				<div class="lstatus__body__item">
					<div class="item__cycle circle" style="background: #00ffd1"></div>
					<div class="item_text">Разработка</div>
				</div>
				<div class="lstatus__body__item">
					<div class="item__cycle circle" style="background: #5c94ff"></div>
					<div class="item_text">Опытная эксплуатация</div>
				</div>
				<div class="lstatus__body__item">
					<div class="item__cycle circle" style="background: #9a5cff"></div>
					<div class="item_text">Производственная эксплуатация</div>
				</div>
				<div class="lstatus__body__item">
					<div class="item__cycle circle" style="background: #ff3dd4"></div>
					<div class="item_text">Развитие</div>
				</div>
				<div class="lstatus__body__item">
					<div class="item__cycle circle" style="background: #ff5c5c"></div>
					<div class="item_text">Вывод</div>
				</div>
				<div class="lstatus__body__item">
					<div class="item__cycle circle" style="background: #ff0000"></div>
					<div class="item_text">Отменен</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MenuSystem from "@/components/menu/MenuSystem.vue";
export default {
	name: "SystemsSettings",
	components: { MenuSystem },
};
</script>

<style scoped>
#system-settings {
	display: flex;
	flex-direction: column;
}
#system-menu {
	display: flex;
	max-width: 240px;
}
#logo-iptimizer {
	display: flex;
	width: 100%;
	height: 90px;
	align-items: center;
	justify-content: center;
	gap: 10px;
	font-size: 20px;
	color: #485afd;
	font-weight: 700;
	border-bottom: 1px solid #c6c6c6;
}
#legend-status {
	display: flex;
	flex-direction: column;
	padding: 34px 20px 15px 20px;
}

#lstatus__body {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.lstatus__body__item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 12pt;
	height: 32px;
	gap: 10px;
}
.item__cycle {
	height: 20px;
	min-width: 20px;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

	shape-outside: circle();
	clip-path: circle();
	align-items: center;
}
</style>
