<template>
	<div id="McreateProduct">
		<!-- Button trigger modal -->
		<button
			id="add_system_button"
			type="button"
			class="btn btn-outline-secondary"
			data-bs-toggle="modal"
			data-bs-target="#exampleModal"
			@click="createProduct"
		>
			Добавить&nbsp;систему
		</button>

		<!-- Modal -->
		<div
			class="modal fade"
			id="exampleModal"
			tabindex="-1"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h1 class="modal-title fs-5" id="exampleModalLabel">
							Создать продукт
						</h1>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<attribute-text
							:objText="{ title: 'Название продукта', value: '', id: -1 }"
							customClass="modal-create-product"
						></attribute-text>
						<attribute-text
							:objText="{
								title: 'PO',
								value: '',
								id: -2,
								customClass: 'modal-create-product',
							}"
							customClass="modal-create-product"
						></attribute-text>
						<attribute-text
							:objText="{
								title: 'Код продукта',
								value: '',
								id: -3,
								customClass: 'modal-create-product',
							}"
							customClass="modal-create-product"
						></attribute-text>
						<attribute-text
							:objText="{
								title: 'Последняя версия',
								value: '',
								id: -4,
								customClass: 'modal-create-product',
							}"
							customClass="modal-create-product"
						></attribute-text>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-success"
							data-bs-dismiss="modal"
							@click="saveNewProduct"
						>
							Создать
						</button>
						<button
							type="button"
							class="btn btn-danger"
							data-bs-dismiss="modal"
							@click="cancelCreateProduct"
						>
							Отменить
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AttributeText from "@/components/cardsInterface/cardAttributes/productAttributes/AttributeText.vue";
export default {
	name: "McreateProductDefault",
	components: {
		AttributeText,
	},
	props: {},
	data() {
		return {
			elements: [],
			objProduct: {
				codeProduct: String,
				nameProduct: String,
				productOwner: String,
				// status: 0,
				latestVersion: String,
				// releaseDate: String
			},
			dictNameAttributeId: {
				1: "nameProduct",
				2: "productOwner",
				3: "codeProduct",
				4: "latestVersion",
			},
		};
	},
	methods: {
		createProduct() {
			this.elements = document.querySelectorAll(".modal-create-product");
			if (!this.elements) return;
			this.elements.forEach((element) => {
				element.readOnly = false;
			});
		},
		cancelCreateProduct() {
			if (!this.elements) return;
			this.elements.forEach((element) => {
				element.value = "";
			});
		},
		saveNewProduct() {
			let newProduct = {};
			this.elements.forEach((element) => {
				newProduct[
					this.dictNameAttributeId[Math.abs(element.dataset.inputId)]
				] = element.value;
			});
			this.postNewProduct(newProduct);
		},
		async postNewProduct(newProduct) {
			console.log("перед отправкой", JSON.stringify(newProduct));
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(newProduct),
			};

			fetch("https://api.tryhard.fun/Card/CreateCard", requestOptions)
				.then((response) => response.json())
				.then((data) => console.log(data))
				.catch((error) => {
					console.log(
						`Error: in McreateProductDefault in postNewProduct ${error}`
					);
				});
		},
	},
};
</script>

<style scoped>
.modal-footer {
	display: flex;
	justify-content: space-around;
}
</style>
