// import { createApp } from "vue";
// // import App from "./App_test.vue";
// import App from "@/App.vue";
// import router from "./router/router.js"


import "bootstrap/dist/css/bootstrap.css";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.js";

import DrawAttribute from "@/components/cardsInterface/cardAttributes/DrawAttribute.vue";
import GroupAttribute from "@/components/cardsInterface/cardAttributes/GroupAttribute.vue";
import WorkerAttribute from "@/components/cardsInterface/cardAttributes/teamAttributes/WorkerAttribute.vue";


import '@/styles/styles.css';


// const app = createApp(App).use(bootstrap);
// app
//     .use(router)
//     .mount("#app");
import { createApp } from 'vue';
import App from './App.vue';
// import App from '../src/test/TestApp.vue';
import router from '@/router/router';

const app = createApp(App);

app
    .component('DrawAttribute', DrawAttribute)
    .component('GroupAttribute', GroupAttribute)
    .component('WorkerAttribute', WorkerAttribute)
    .use(router)
    .use(bootstrap)
    .mount('#app');
