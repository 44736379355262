<template>
	<router-link class="text-decoration-none" :to="{ path: objChanges.path }">
		<div class="card text-bg-light" style="max-width: 18rem">
			<div class="card-header">
				Изменено поле "{{ objChanges.changeField }}"
			</div>
			<div class="card-body">
				<h5 class="card-title">{{ objChanges.nameProduct }}</h5>
				<p class="card-text">
					Старое значение: {{ objChanges.oldValue }} <br />
					Новое значение: {{ objChanges.newValue }} <br />
					Дата изменения: {{ objChanges.dateChange }}
				</p>
			</div>
		</div>
	</router-link>
</template>

<script>
export default {
	name: "CardChange",
	props: {
		objChanges: {
			id: Number,
			idCard: Number,
			changeField: String,
			nameProduct: String,
			oldValue: String,
			newValue: String,
			dateChange: String,
			path: String,
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
	background-color: white;
	font-size: 20px;
	padding-right: 8px;
}
</style>
