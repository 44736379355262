<!-- компонент для системы и настроек
https://www.figma.com/file/9VZ80qvTK8NKmf2hDyONjI/Untitled?type=design&node-id=0-1&mode=design&t=Tt84mVYbrkItPHfq-0 - ссылка на дизайн -->
<template>
    <ul id="listComponents" class="list-group">
        <menu-item v-for="(card, index) in arrayLogoAndTitles" :key="index" 
        :logo="card.logo" :title="card.title" :path="card.path" :param="card.param"
        />
    </ul>
</template>

<script>
import MenuItem from "./MenuItem.vue";
export default {
    name: "MenuSystem",
    components: { MenuItem },
    props: {
		arrayLogoAndTitles: {
            type: Array,
            required: true
        }
	},

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#listComponents{
    display: flex;
    flex-direction: column;
}
</style>
