<template>
	<!-- <li class="nav-item">
      <a class="nav-link active" aria-current="page" href="#">Active</a>
    </li> -->
	<router-link class="text-decoration-none" :to="{ path: path }">
		<li v-if="this.$route" id="menu-item" class="btn btn-outline-primary menu-items" 
			aria-current="true" @click="addStyle">
			<!-- {{ this.$route }} -->
			<div
				id="menu-item__logo"
				:style="{ backgroundImage: 'url(' + logo + ')' }"
			></div>
			<div class="menu-item__title">
				{{ title }}
			</div>
		</li>
	</router-link>
</template>

<script>
export default {
	name: "MenuItem",
	props: {
		logo: String,
		title: String,
		path: String,
		param: String
	},
	methods: {
		addStyle(event){
			const elements = document.querySelectorAll('.active_menu');
			elements.forEach((item)=>{
				item.classList.remove('active_menu');
			})
			const menuItem = event.currentTarget;
			console.log(event, ' ', this.param);
			console.log(menuItem);
			menuItem.classList.add('active_menu');
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#menu-item {
	min-width: 200px;
	min-height: 24px;
	display: flex;
	border: 0px;
	gap: 10px;
}
#menu-item__logo {
	min-width: 24px;
	min-height: 24px;
	background-repeat: no-repeat;
	background-position: center;
}
#menu-item__title {
	justify-content: flex-end;
}

.menu-item__title {
	text-align: left;
}
.activate-item {
	background: #fff;
}

</style>
