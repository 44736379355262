<!-- https://getbootstrap.com/docs/5.3/forms/select/ -->
<template>
    <label for="exampleFormControlInput1" class="form-label">{{ objSelect.title }}</label>
    <select class="form-select" :data-input-id="objSelect.id" :data-value="objSelect.value" :data-array="objSelect.elements" :disabled="true" aria-label="Default select example">
        <!-- <option selected></option> -->
        <option v-for="(element, index) in objSelect.elements" 
                :key="element" :selected="index==objSelect.value?true:false" :value="index">
                {{element}} 
        </option>
        <!-- дописать условие на выбранность -->
    </select>
</template>

<script>
    export default {
        name: "AttributeList",
        props: {
            objSelect: {
                id: Number,
                title: String,
                value: Number,
                elements: Array,
                typeElement: String,
            }
        }
        
    }
</script>

<style lang="scss" scoped>

</style>