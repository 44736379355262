<template>
	<div id="system-menu-card">
		<menu-system
			:arrayLogoAndTitles="[
				{
					logo: 'https://icons.getbootstrap.com/assets/icons/info-square.svg',
					title: 'Общая информация',
					path: '/systems/card/' + this.cardId + '/info',
					param: 'info'
				},
				{
					logo: 'https://icons.getbootstrap.com/assets/icons/bezier.svg',
					title: 'Функциональное описание',
					path: '/systems/card/' + this.cardId + '/func',
					param: 'func'
				},
				{
					logo: 'https://icons.getbootstrap.com/assets/icons/cpu.svg',
					title: 'Техническое описание',
					path: '/systems/card/' + this.cardId + '/tech',
					param: 'tech'
				},
				{
					logo: 'https://icons.getbootstrap.com/assets/icons/people.svg',
					title: 'Команда',
					path: '/systems/card/' + this.cardId + '/team',
					param: 'team'
				},
				{
					logo: 'https://icons.getbootstrap.com/assets/icons/cash-stack.svg',
					title: 'Стоимость разработки',
					path: '/systems/card/' + this.cardId + '/cost',
					param: 'cost'
				},
				{
					logo: 'https://icons.getbootstrap.com/assets/icons/gear.svg',
					title: 'Лицензия и выплаты',
					path: '/systems/card/' + this.cardId + '/license',
					param: 'license'
				},
				{
					logo: 'https://icons.getbootstrap.com/assets/icons/file-text.svg',
					title: 'Документы',
					path: '/systems/card/' + this.cardId + '/docs',
					param: 'docs'
				},
				// { logo: 'https://icons.getbootstrap.com/assets/icons/clipboard-data.svg', title: 'Отчеты и справки', path: '/systems/card/'+this.cardId+'/certificates'},
				{
					logo: 'https://icons.getbootstrap.com/assets/icons/shield-shaded.svg',
					title: 'Патентирование',
					path: '/systems/card/' + this.cardId + '/patent',
					param: 'patent'
				},
			]"
		/>
	</div>
</template>

<script>
import MenuSystem from "@/components/menu/MenuSystem.vue";
export default {
	name: "CardAttributes",
	components: { MenuSystem },
	props: {
		activeAttribute: String,
		cardId: Number,
	},
	created(){

	}
};
</script>

<style lang="scss" scoped>
</style>
