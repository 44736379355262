<template>
  <!-- <div>
    <button type="button" class="btn-close" aria-label="Close"
      data-bs-toggle="modal" data-bs-target="#modalDelete1"></button>
    <div class="modal fade" :id="'modalDelete'+1" :aria-labelledby="'modalDeleteLabel'+1"
      tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" :id="'modalDeleteLabel'+1">
              Удаление сотрудника 1
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">Удалить сотрудника?</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
              Удалить
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Отменить
            </button>
          </div>
        </div>
      </div>
    </div>

    <button type="button" class="btn-close" aria-label="Close"
      data-bs-toggle="modal" :data-bs-target="'#modalDelete'+2"></button>
    <div class="modal fade" :id="'modalDelete'+2" :aria-labelledby="'modalDeleteLabel'+2"
      tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" :id="'modalDeleteLabel'+2">
              Удаление сотрудника 2
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">Удалить сотрудника? 2</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
              Удалить 2
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Отменить 2
            </button>
          </div>
        </div>
      </div>
    </div>





















    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1">
      Launch demo modal
    </button> -->

    <!-- Modal -->
    <!-- <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel2">Modal title 1</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            привет1
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>



    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2">
      Launch demo modal
    </button> -->

    <!-- Modal -->
    <!-- <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel2">Modal title 2</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            привет2
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div></div>
</template>

<script>


export default {

}
</script>


<style scoped></style>