<template>
	<div id="card-options">
        <card-interface :cardId='parseInt(this.$route.params.id)' attributeId='info' ></card-interface>
    </div>
</template>
<!-- this.$route.params.attribute -->
<script>
import CardInterface from "@/components/cardsInterface/CardInterface.vue";
export default {
	name: "PageCardOptions",
	components: {
		CardInterface
	},
};
</script>

<style></style>