<template>
	<div v-if="localCards.length > 0" id="list-card-product" class="cardLists">
		<h1 id="title-products">
			{{ title }}
		</h1>
		<div id="listCards">
			<card-product
				@click="fetchDataProducts_l"
				v-for="(card, index) in localCards"
				:key="index"
				:objCard="JSON.parse(JSON.stringify(card))"
			/>
		</div>
	</div>
	<div v-else id="emptyListChanges">
		<h1>Нет систем</h1>
	</div>
</template>

<script>
import CardProduct from "@/components/cards/CardProduct.vue";
export default {
	name: "ListCardChanges",
	components: { CardProduct },
	props: {
		title: String,
	},
	data() {
		return {
			localCards: [],
		};
	},
	created() {
		this.fetchDataProducts_l();
	},
	methods: {
		async fetchDataProducts_l() {
			try {
				const response = await fetch("https://api.tryhard.fun/Card/GetCards");
				const data = await response.json();
				if (!data) {
					console.log("SERVER: Cards of Products is empty");
					return;
				}
				this.localCards = JSON.parse(JSON.stringify(data));
				this.localCards.forEach((item) => {
					item.path = "/systems/card/" + item.id + "/info";
				});
			} catch (e) {
				console.log("Error fetching in get data Changes");
			}
		},
		async fetchUpdateDataProducts(text) {
			try {
				const response = await fetch(
					`https://api.tryhard.fun/Card/FindSystems?request=${text}`
				);
				const data = await response.json();
				console.log(
					"SERVER: data from searching",
					JSON.parse(JSON.stringify(data))
				);
				if (!data) {
					console.log("SERVER: Cards of Products is empty -> set [] card");
					return;
				}
				this.localCards = JSON.parse(JSON.stringify(data));
				this.localCards.forEach((item) => {
					item.path = "/systems/card/" + item.id + "/info";
				});
			} catch (e) {
				console.log("Error fetching in get data Search products");
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
	background-color: white;
	font-size: 20px;
	padding-right: 8px;
}
#list-card-product {
	display: flex;
	flex-direction: column;
}
#listCards {
	display: flex;
	/* justify-content: space-evenly; */
	align-items: flex-start;
	flex-wrap: wrap;
	gap: 10px;
}
</style>

<!-- // localCards: [
            //     {
            //         codeProduct: "A1",
            //         nameProduct: "Управление инновациями",
            //         productOwner: "Крылов Виктор Петрович",
            //         status: 1,
            //         latestVersion: "1.0.2",
            //         releaseDate: "14.05.2017",
            //     },
            //     {
            //         codeProduct: "B1",
            //         nameProduct: "Департамент содействия международному развитию и коммуникации",
            //         productOwner: "Белов Сергей Александрович",
            //         status: 5,
            //         latestVersion: "7.5.2.3.7",
            //         releaseDate: "21.09.2024",
            //     },
            // ] -->
<!-- localCards: [
            {
                objCard: {
                id: 1,
                codeProduct: "P1",
                nameProduct: "E044. Управление ролями и дост...",
                productOwner: "Семенов Сергей Викторович",
                status: 1,
                latestVersion: "1.0.2",
                releaseDate: "25.08.2023",   
                },
                path: "/systems/card/1/info"
            },
            {
                objCard: {
                id: 2,
                codeProduct: "H3",
                nameProduct: "Z28. Управление подразделениями контроля качества",
                productOwner: "Киселев Андрей Антонович",
                status: 1,
                latestVersion: "7.4.10.23",
                releaseDate: "17.06.2024",   
                },
                path: "/systems/card/2/info"
            }


            localCards: [
                {
                    id: 1,
                    codeProduct: "A1",
                    nameProduct: "Управление инновациями",
                    productOwner: "Крылов Виктор Петрович",
                    status: 1,
                    latestVersion: "1.0.2",
                    releaseDate: "14.05.2017",
                    path: "/systems/card/1/info"
                },
            ]
        ] -->
