<template>
	<div class="page-systems">
		<div class="systems__header">
			<search-product
				@searchClicked="updateListCardChanges($event)"
				@enterPress="updateListCardChanges($event)"
			></search-product>
			<mcreate-product-default></mcreate-product-default>
		</div>
		<div v-if="isActive" class="systems_statuses">
			<span v-if="isActive" class="badge text-bg-primary">
				<div class="bodges-find">
					<a id="find_bodges_title">Поиск "{{ text }}"</a>
					<button
						type="button"
						class="btn-close btn-close-white"
						aria-label="Close"
						@click="censleFind()"
					></button>
				</div>
			</span>
		</div>
		<div class="systems__body">
			<list-card-changes title="Последние изменения" />
			<list-card-products ref="listCardChanges" title="Системы" />
		</div>
	</div>
</template>

<script>
import ListCardChanges from "@/components/ListCardChanges.vue";
import ListCardProducts from "@/components/ListCardProducts.vue";

import McreateProductDefault from "@/components/modals/McreateProductDefault.vue";
import SearchProduct from "@/components/searching/SearchProduct.vue";
export default {
	name: "PageSystems",
	components: {
		ListCardChanges,
		ListCardProducts,
		McreateProductDefault, // модальное окно добавления продукта
		SearchProduct, //поисковая строка
	},
	data() {
		return {
			isActive: false,
			text: String,
		};
	},
	methods: {
		updateListCardChanges(text) {
			if (text === "") {
				this.censleFind();
				this.isActive = false;
				return;
			}
			this.isActive = true;
			this.text = text;
			this.$refs.listCardChanges.fetchUpdateDataProducts(text);
		},
		censleFind() {
			this.isActive = false;
			document.getElementById("searchingText").value = "";
			this.$refs.listCardChanges.fetchDataProducts_l();
		},
	},
};
</script>

<style>
.systems__header {
	display: flex;
	align-items: center;
	gap: 10px;
}
</style>
