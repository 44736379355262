<template>
	<div>
		<label for="exampleFormControlInput1" class="form-label">{{
			objText.title
		}}</label>
		<input
			type="text"
			:data-input-id="objText.id"
			class="interface_input form-control"
			id="exampleFormControlInput1"
			:class="customClass"
			:value="objText.value"
			:placeholder="objText.value"
			readonly
		/>
	</div>
</template>

<script>
export default {
	name: "AttributeText",
	props: {
		objText: Object, //title: String, value: String, id: Number
		customClass: String,
	},
};
</script>

<style scoped></style>
