import { createRouter, createWebHistory } from 'vue-router';
// import nullPage from '@/pages/nullPage.vue';
import PageSystems from '@/pages/PageSystems.vue';
// import SystemsSettings from '@/SystemsSettings.vue';
import PageCardOptions from "@/pages/PageCardOptions.vue";
import PageTest from "@/pages/PageTest.vue";

const NotFound = { template: '<h2>Page Not Found</h2>' };
const inDeveloping = { template: '<h2>В разработке</h2>' };

const routes = [
  { path: '/', redirect: '/systems' },
  // { path: '/main', component: SystemsSettings },
  { path: '/systems', component: PageSystems, },
  { path: '/settings', redirect: '/inDeveloping' },
  { path: '/systems/card/:id', component: PageCardOptions, },
  { path: '/systems/card/:id/:attribute', component: PageCardOptions, },
  { path: '/test', component: PageTest },
  { path: '/404', component: NotFound }, // Добавляем отдельный маршрут для страницы 404
  { path: '/inDeveloping', component: inDeveloping }, 
  { path: '/:pathMatch(.*)*', redirect: '/404' }, 
];

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
});

export default router;