<template>
	<form id="attribute-info">
		<div id="attributes-list-screen">
			<div id="attributes-list">
				<DrawAttribute
					v-for="attribute in attributeObj"
					:obj-attribute="attribute"
					:key="attribute.id"
				/>
			</div>
		</div>
		<div id="attribute-info__options">
			<div v-if="this.$route.params.attribute == 'team'" id="addEmployee">
				<button
					type="button"
					class="btn btn-primary interface_delete_employee"
					aria-label="Close"
					style="display: none"
					@click="addEmployee"
				>
					Добавить сотрудника
				</button>
			</div>
			<div v-if="isReadOnly" id="options__mode-readonly">
				<button type="button" class="btn btn-primary" @click="setEditMode">
					Редактировать
				</button>
			</div>
			<div v-else id="options__mode-edit">
				<div id="edit__save-button">
					<button type="button" class="btn btn-primary" @click="setSaveMode">
						Сохранить
					</button>
				</div>
				<div id="edit__cancel-button">
					<button type="button" class="btn btn-danger" @click="setCancelMode">
						Отменить
					</button>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import DrawAttribute from "@/components/cardsInterface/cardAttributes/DrawAttribute.vue";
export default {
	name: "AttributesInfo",
	components: { DrawAttribute },
	props: {
		cardId: Number,
		attributeObj: Object,
	},
	data() {
		return {
			isReadOnly: true,
			oldVal: [],
		};
	},
	watch: {
		"$route.path": {
			handler() {
				this.setReadOnlyState(true, ".interface_input");
				this.setReadOnlyState(true, ".form-select");
				this.isReadOnly = true;
			},
		},
	},
	created() {
		this.getInputs;
		this.setReadOnlyState(true, ".interface_input");
		this.setReadOnlyState(true, ".form-select");
	},
	methods: {
		someFuncToElementsFromCollection(nameCollection, someFunc) {
			const elements = document.querySelectorAll(nameCollection);
			if (!elements) return;
			elements.forEach((element, i) => {
				if (someFunc) someFunc(element, i);
			});
		},
		setReadOnlyState(state, nameCollection) {
			this.someFuncToElementsFromCollection(nameCollection, (elem) => {
				//attribute-text
				elem.readOnly = state;
			});
		},
		setEditMode() {
			//для всех инпутов readonly = false
			this.setReadOnlyState(false, ".interface_input"); // обработали все инпуты в attribute-text и text-area
			this.oldVal = [];
			this.someFuncToElementsFromCollection(".interface_input", (elem) => {
				this.oldVal.push(elem.value);
			}); // сохранили все значения, чтобы при отмене вернуть старые значения элементов

			// TODO добавить обработку всех select
			this.someFuncToElementsFromCollection(".form-select", (elem) => {
				// класс form-select attribute-list
				elem.disabled = false;
				this.oldVal.push(elem.value);
			});

			this.someFuncToElementsFromCollection(
				".interface_delete_employee",
				(elem) => {
					// TODO отрисовать интерфейс удаления
					elem.style = "";
				}
			);
			this.isReadOnly = false; // TODO скрыть кнопку редактировать и отрисовать кнопки сохранить и отменить
		},
		setSaveMode() {
			let newData = {};

			this.isReadOnly = true;
			this.someFuncToElementsFromCollection(".interface_input", (elem) => {
				if (elem.dataset.inputId) newData[elem.dataset.inputId] = elem.value;
				elem.readOnly = true;
			});
			this.someFuncToElementsFromCollection(".form-select", (elem) => {
				if (elem.dataset.inputId) newData[elem.dataset.inputId] = elem.value;
				elem.disabled = true;
			});
			console.log(`logging: before save changes ${newData}`);
			document
				.querySelectorAll(".interface_delete_employee")
				.forEach((elem) => {
					elem.style = "display: none;";
				});
			this.putNewCardInfo(newData);
		},
		setCancelMode() {
			document
				.querySelectorAll(".interface_delete_employee")
				.forEach((elem) => {
					elem.style = "display: none;";
				});
			this.isReadOnly = true;

			let i = 0;
			this.someFuncToElementsFromCollection(".interface_input", (elem) => {
				elem.value = this.oldVal[i];
				i++;
				elem.readOnly = true;
			});
			this.someFuncToElementsFromCollection(".form-select", (elem) => {
				// класс form-select attribute-list
				elem.value = this.oldVal[i];
				i++;
				elem.disabled = true;
			});
		},
		async putNewCardInfo(newInfo) {
			console.log(JSON.stringify(newInfo));

			const requestOptions = {
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(newInfo),
			};

			fetch("https://api.tryhard.fun/Card/PutData", requestOptions)
				.then((response) => response.json())
				.then((data) => console.log(data))
				.catch((error) => {
					console.log(`Error in attributeInfo: ${error}`);
				});
		},
		async addEmployee() {
			////https://api.tryhard.fun/Card/AddWorker/1
			const requestOptions = {
				method: "POST",
			};
			fetch(
				`https://api.tryhard.fun/Card/AddWorker/${this.$route.params.id}`,
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => console.log(data))
				.catch((error) => {
					console.log();
					`Error: comp: Worker in addEmployee: ${error}`;
					console.log();
					`url: https://api.tryhard.fun/Card/AddWorker/${this.$route.params.id}`;
				});
			this.$router.go(0);
		},
	},
};
</script>

<style scoped>
#attribute-info {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

#attribute-info__options {
	display: flex;
	gap: 10px;
}

#options__mode-edit {
	display: flex;
	gap: 10px;
}
</style>
